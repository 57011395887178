<template>
  <Layout>
    <PageHeader :title="state.title" :items="state.items" />
    <div class="card-header">
      <h6 class="fs-16 mb-0 custom-h flex align-items-center">{{ $t('facility.app-info') }}</h6>
    </div>
    <div class="card rounded-3">
      <TableCommon
          ref="tableFacilityApplication"
          :columns="state.column"
          :endpoint="`${ADMIN_API_PATH.ADMIN_FACILITIES}/user-facilities`"
          :query="{role: ROLES.ADMIN}"
          @onEdit="navigateUpdateFacility"
          @onDelete="handleDelete"
      />
    </div>

  </Layout>
</template>

<script>
import {reactive, ref } from "vue";
import { get } from "lodash";
import * as moment from "moment/moment";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import {ADMIN_API_PATH, ColorStatus, ColorType, DATE_FORMAT, FacilitiesRequestStatus, ROLES} from "@/constants";
import { facilityService } from "@/services";
import i18n from "@/i18n";
import router from "@/router";
import Swal from "sweetalert2";
import TableCommon from "@/components/common/TableCommon.vue";
import store from "@/state/store";

export default {
  computed: {
    ROLES() {
      return ROLES
    },
    ADMIN_API_PATH() {
      return ADMIN_API_PATH
    }
  },
  components: {
    Layout,
    PageHeader,
    TableCommon
  },

  setup() {
    const dropZoneRef = ref(null);
    const textRef = ref('');
    const state = reactive({
      query: {
        search: '',
        page: 1,
        pageSize: 10,
        role: ROLES.ADMIN
      },
      items: [
        {
          text: i18n.global.t('facility.management'),
          active: true
        },
        {
          text: i18n.global.t('facility.app-info'),
          active: true,
        },
      ],
      loading: false,
      hasMore: true,
      data: [],
      pagination: null,
      loadingExport: false,
      openModal: false,
      importType: 1,
      title: i18n.global.t('facility.management'),
      column: [
        {
          sortable: false,
          label: '',
          type: 'stt',
          maxWidth: 200
        },
        {
          key: 'facilityName',
          sortable: true,
          label: i18n.global.t('facility.name'),
          maxWidth: 200
        },
        {
          key: 'facilityAddress',
          dynamicText: (row) => `${get(row, 'facilities[0].address4', '')}${get(row, 'facilities[0].address1', '')}${get(row, 'facilities[0].address2', '')}${get(row, 'facilities[0].address3', '')}`,
          sortable: true,
          label: i18n.global.t('facility.address'),
          maxWidth: 200
        },
        {
          key: 'status',
          type: 'status',
          dynamicText: (row) => `<span class="badge bg-${ColorType[get(row, "requestType", '')]}-subtle text-${ColorType[get(row, "requestType", '')]}">
                   ${i18n.global.t(`facilityApplication.requestType.${get(row, "requestType", '')}`)}</span>`,
          sortable: false,
          label: i18n.global.t('t-type'),
          maxWidth: 200
        },
        {
          key: 'createdBy.name',
          dynamicText: (row) => `${row.createdBy?.name.length > 0 ? row.createdBy?.name : `<div class="text-center">-</div>`}`,
          sortable: true,
          label: '申請者',
          maxWidth: 200
        },
        {
          key: 'createdAt',
          type: 'datetime',
          sortable: true,
          label: '申請日',
          maxWidth: 200
        },
        {
          key: 'status',
          type: 'status',
          dynamicText: (row) => `<span class="badge bg-${ColorStatus[get(row, "requestStatus", '')]}-subtle text-${ColorStatus[get(row, "requestStatus", '')]}">
                ${i18n.global.t(`facilityApplication.status.${get(row, "requestStatus", '')}`)}</span>`,
          sortable: false,
          label: i18n.global.t('t-status'),
          maxWidth: 200
        },

        {
          key: 'updatedBy.name',
          dynamicText: (row) => `${row.updatedBy?.name.length > 0 ? row.updatedBy?.name : `<div class="text-center">-</div>`}`,
          sortable: true,
          label: "操作者",
          maxWidth: 200
        },
        {
          key: 'updatedAt',
          type: 'datetime',
          sortable: true,
          label: "操作日",
          maxWidth: 200
        },

        {
          key: 'action',
          options: ['edit', 'delete'],
          dynamicGetName: "facilityName",
          type: 'action',
          sortable: false,
          label: '',
          /*isDisabledEdit: (row) => checkDisableButtonEdit(row),
          isDisabledDelete: (row) => checkDisableButtonDelete(row)*/
        }
      ]
    })

    const formatDate = (date) => {
      return moment(date).format(DATE_FORMAT)
    }
    const navigateTo = (routerName) => {
      router.push(routerName)
    }

    const navigateUpdateFacility = (facilityID) => {
      router.push({
        name: "facility-update-app-management", params: {
          facilityId: facilityID
        }
      })
    }

    const paginate = (pageNumber) => {
      state.query.page = pageNumber;
    }

    const approveFacility = async (facilityId) => {
      try {
        await facilityService.approveFacility(facilityId);

        await Swal.fire({
          title: "",
          text: "Approve success",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });

      } catch (e) {
        await Swal.fire({
          title: "",
          text: e.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(e)
      }
      console.log(facilityId)
    }

    const rejectFacility = (facilityId) => {
      console.log(facilityId)
    }

    const checkDisableButtonEdit = (row) => {
      const status = get(row, 'requestStatus', "REJECTED")
      return status === FacilitiesRequestStatus.REJECTED || status === FacilitiesRequestStatus.APPROVED;
    }
    const checkDisableButtonDelete = (row) => {
      const status = get(row, 'requestStatus', "REJECTED")
      return status === FacilitiesRequestStatus.REJECTED || status === FacilitiesRequestStatus.APPROVED;
    }

    return {
      checkDisableButtonDelete,
      checkDisableButtonEdit,
      state,
      formatDate,
      navigateUpdateFacility,
      paginate,
      get,
      textRef,
      navigateTo,
      dropZoneRef,
      approveFacility,
      rejectFacility
    }
  },
  methods: {
    async handleDelete(value) {
      const {id, name} = value;
      const accept = await Swal.fire({
        title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('facilityApplication.confirmDelete', {name: name})}</h2>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t('yes'),
        cancelButtonText: i18n.global.t('cancel'),
      });


      if (accept.value) {
        try {
          await facilityService.deleteFacilityApplication(id);

          this.$refs.tableFacilityApplication.refreshTable();
        } catch (e) {
          const message = get(e, 'response.data.message') || e.message || '';
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: message
          });
        }
      }
    }
  }
}

</script>

<style scoped>
.card::v-deep .min-h-38 {
  min-height: 38px;
}

.uploadLabel {
  padding: 8px 16px;
  border: 1px solid #ccc;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
}

.hidden {
  display: none;
}

.modalSpacing {
  row-gap: 14px;
}

.download-button {
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  column-gap: 12px;
}

.highlight {
  font-size: 0.875em;
  color: #ed5e5e;
  white-space: pre-line;
}

.error-message {
  word-wrap: break-word;
  max-height: 114px;
  overflow: auto;
  word-break: break-all;
  white-space: pre-line;
}

.dropZone {
  min-height: 150px;
  border: 1px dashed #ccc;
  border-radius: 4px;
}

.dropZone>i {
  font-size: 30px;
}

.custom-h {
  min-height: 38px;
}

.custom-items {
  align-items: center !important;
}
</style>
